<template>
  <v-card>
    <v-card-title>
      Fee Accounts with Zero Balance
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="studentZeroBalanceFees"
      :loading="false"
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.student.id }}</td>
        <td>
          <v-chip
            v-for="fee in props.item.fees"
            :key="props.item.student.id+fee.id"
            small
            @click="toAccount(props.item.student.id, fee.id)"
          >
            {{ fee.type }}
          </v-chip>
        </td>
        <td>
          <v-icon @click="toAccount(props.item.student.id)">
            search
          </v-icon>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'ZeroBalanceFeeList',
  data() {
    return {
      studentFeeBalances: [],
      headers: [
        { text: 'Student ID' },
        { text: 'Uncleared Fees' },
        { text: 'Actions' },
      ],
    }
  },
  computed: {
    studentZeroBalanceFees() {
      const studentZeroBalanceFees = []
      let matchedFee = null
      this.studentFeeBalances.forEach((studentFee) => {
        if (studentFee.balance !== '0') return
        matchedFee = studentZeroBalanceFees
          .find(item => item.student.id === studentFee.studID)
        if (matchedFee) {
          matchedFee.fees.push({
            id: studentFee.feeID,
            type: studentFee.feeType,
            balance: studentFee.balance,
          })
        } else {
          studentZeroBalanceFees.push({
            student: {
              id: studentFee.studID,
              firstName: studentFee.firstName,
              lastName: studentFee.lastName,
            },
            fees: [{
              id: studentFee.feeID,
              type: studentFee.feeType,
              balance: studentFee.balance,
            }],
          })
        }
      })
      return studentZeroBalanceFees
    },
  },
  created() {
    this.$store.dispatch('fees/getZeroBalanceFees')
      .then((data) => { this.studentFeeBalances = data })
  },
  methods: {
    toAccount(studID, feeID) {
      this.$router.push(`/student-account/${studID}/fee/${feeID}`)
    },
  },
}
</script>

<style>
</style>
