<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        sm12
      >
        <v-card
          color="indigo"
          dark
        >
          <v-card-title>
            Student Fees Report
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex
        xs12
        sm12
        class="py-2"
      >
        <v-btn-toggle
          v-model="toggle_multiple"
          multiple
        >
          <v-btn flat>
            Due in Two Weeks
          </v-btn>
          <v-btn flat>
            Past Due
          </v-btn>
          <v-btn flat>
            Zero Balance Accounts
          </v-btn>
          <v-btn flat>
            Today's Transactions
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex
        v-if="toggle_multiple.includes(0)"
        sm12
        :md6="toggle_multiple.length > 1"
      >
        <upcoming-due-list />
      </v-flex>
      <v-flex
        v-if="toggle_multiple.includes(1)"
        sm12
        :md6="toggle_multiple.length > 1"
      >
        <uncleared-fee-list />
      </v-flex>
      <v-flex
        v-if="toggle_multiple.includes(2)"
        sm12
        :md6="toggle_multiple.length > 1"
      >
        <zero-balance-fee-list />
      </v-flex>
      <v-flex
        v-if="toggle_multiple.includes(3)"
        sm12
        :md6="toggle_multiple.length > 1"
      >
        <recent-transaction-list />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import RecentTransactionList from '@/components/student-fee-report/RecentTransactionList.vue'
import UnclearedFeeList from '@/components/student-fee-report/UnclearedFeeList.vue'
import UpcomingDueFeeList from '@/components/student-fee-report/UpcomingDueFeeList.vue'
import ZeroBalanceFeeList from '@/components/student-fee-report/ZeroBalanceFeeList.vue'

export default {
  name: 'StudentFeesReport',
  components: {
    'recent-transaction-list': RecentTransactionList,
    'uncleared-fee-list': UnclearedFeeList,
    'upcoming-due-list': UpcomingDueFeeList,
    'zero-balance-fee-list': ZeroBalanceFeeList,
  },
  data() {
    return {
      toggle_multiple: [0],
    }
  },
}
</script>

<style>
</style>
