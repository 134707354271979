<template>
  <v-card>
    <v-card-title>
      Past Due
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="unclearedFees"
      :loading="false"
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.studID }}</td>
        <td>
          <v-chip
            v-for="fee in props.item.fees"
            :key="fee.feeID"
            small
            @click="toAccount(props.item.studID, fee.feeID)"
          >
            {{ fee.type }}
          </v-chip>
        </td>
        <td>
          <v-icon @click="toAccount(props.item.studID)">
            search
          </v-icon>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'UnclearedFeeList',
  data() {
    return {
      unclearedFeesMap: {},
      headers: [
        { text: 'Student ID' },
        { text: 'Uncleared Fees' },
        { text: 'Actions' },
      ],
    }
  },
  computed: {
    unclearedFees() {
      return Object.entries(this.unclearedFeesMap)
        .map(([studID, fees]) => ({ studID, fees }))
    },
  },
  created() {
    this.$store.dispatch('fees/getStudentUnclearedFees')
      .then((data) => { this.unclearedFeesMap = data })
  },
  methods: {
    toAccount(studID, feeID) {
      this.$router.push(`/student-account/${studID}/fee/${feeID}`)
    },
  },
}
</script>

<style>
</style>
