<template>
  <v-card>
    <v-card-title>
      Today's transaction
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="recentTrans"
      :loading="false"
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.studID }}</td>
        <td>{{ props.item.feeID }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.createDate }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'RecentTransactionList',
  data() {
    return {
      recentTrans: [],
      headers: [
        { text: 'Student ID' },
        { text: 'Fee ID' },
        { text: 'Amount' },
        { text: 'Time' },
      ],
    }
  },
  created() {
    this.$store.dispatch('transactions/getRecentTransactions')
      .then((data) => { this.recentTrans = data })
  },
}
</script>

<style>
</style>
